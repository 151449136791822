document.addEventListener("DOMContentLoaded", () => {
  const filterToggles = document.querySelectorAll('.filter-toggle');
  const filterContainer = document.querySelector('.filters');

  if(filterContainer){
    filterToggles.forEach((filterToggle) => {
      filterToggle.addEventListener('click', () => {
        filterContainer.classList.toggle('active');
      })
    })
    
  
    document.addEventListener('keydown', function(event) {
      const key = event.key;
      if (key === "Escape") {
        filterContainer.classList.remove('active');
      }
    });
  
    
    document.addEventListener('click', function(event) { 
      const isClickInside = filterContainer.contains(event.target) || event.target.classList.value.indexOf('filter-toggle') !== -1;
  
      if (!isClickInside) {
        filterContainer.classList.remove('active');
      }
    });
  }
  
})