// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "./components/filter";
import jQuery from "jquery";

document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("add-row")) {
    document.getElementById("add-row").addEventListener("click", (e) => {
      e.preventDefault();
      let templateContainer = document.getElementById("row-template");
      let template = templateContainer.querySelector(".fieldset").cloneNode(true);
      let container = document.querySelector(".dynamic-area");
      container.appendChild(template);
      jQuery(".time-input").autotab({ maxlength: 5 });
    });
  }
  if (document.getElementById("clear-rows")) {
    document.getElementById("clear-rows").addEventListener("click", (e) => {
      e.preventDefault();
      let container = document.querySelector(".dynamic-area");
      container.innerHTML = "";
    });
  }

  const abscenceToggles = document.querySelectorAll(".record-absence");
  if (abscenceToggles) {
    abscenceToggles.forEach((abscenceToggle) => {
      abscenceToggle.addEventListener("click", (e) => {
        e.preventDefault();
        abscenceToggle.classList.add("hide");
        abscenceToggle.nextElementSibling.classList.add("show");
      });
    });
  }

  if (jQuery(".time-input.out").length) {
    jQuery(".time-input.out").bind("input", function () {
      const time_in = jQuery(this).closest(".grid-x").find(".time-input.in").val();
      const time_out = jQuery(this).val();

      if (time_out < time_in) {
        this.setCustomValidity("Time out must be after Time in");
      } else {
        this.setCustomValidity("");
      }
    });

    jQuery(".time-input.in").bind("input", function () {
      const time_in = jQuery(this).val();
      const time_out = jQuery(this).closest(".fieldset").prev().find(".time-input.out").val();

      if (time_in < time_out) {
        this.setCustomValidity("Time in must be after the Time out of the previous row");
      } else {
        this.setCustomValidity("");
      }
    });
  }

  if (document.querySelector(".clock")) {
    const el = document.querySelector(".clock");
    let seconds = parseInt(el.getAttribute("data-current-duration"));
    const isActive = el.getAttribute("data-active");
    el.innerText = new Date(seconds * 1000).toISOString().substr(11, 5);

    function incrementSeconds() {
      seconds += 1;
      el.innerText = new Date(seconds * 1000).toISOString().substr(11, 5);
    }

    if (isActive != null) {
      const cancel = setInterval(incrementSeconds, 5000);
    }
  }

  if (document.querySelector(".toggle-weekend")) {
    document.querySelector(".toggle-weekend").addEventListener("click", (e) => {
      e.preventDefault();
      e.target.classList.toggle("active");
      document.querySelectorAll(".cell.weekend").forEach((weekend) => {
        weekend.classList.toggle("collapse-right");
      });

      document.querySelectorAll(".day-row.weekend").forEach((weekend) => {
        weekend.classList.toggle("collapse-top");
      });

      let show_weekend = 0;
      if (e.target.classList.contains("active")) {
        e.target.innerText = "Hide Weekend";
        show_weekend = 1;
      } else {
        e.target.innerText = "Show Weekend";
      }

      console.log(show_weekend);
      setCookie("show_weekend", show_weekend, 1);
    });
  }
});

function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}
